.vidFile{
    display: none;
}
.optionGroup {
    font-weight: bold;
    /* font-style: italic; */
}
    
.optionChild {
    padding-left: 15px !important;
}
audio{
    max-width: 100%;
}
#list_files{
    margin-top: 12px;
}
#list_files span{
    border-bottom-style: dotted;
    border-color: #b34858;
    padding: 1px 1px;
    margin-right: 12px;
   
}
.btn-tool{
    color:#dc3545
}
.search-form{
    border: 1px solid #ced4da;
}
.timeline-body video{
  width: 100%;
}

.thumb a{
    overflow-x: hidden;
}
.timeline-header{
    overflow-x: hidden;
    overflow-y: hidden;
}
.thumb video{
     width:100%;
    /* height: 247px;
    width:247px;   */
}
.video_timeline{
    margin-bottom: 0px !important;
}
.video_timeline::before{
    background: none !important;
}
.video_timeline_item{
    margin-left: 0px !important;
}



.thumb img{
    height:254px;
    width:100%; 
   }

.img_timeline_item .timeline-header{
    line-height: 1.5em;
    height: 3em;       /* height is 2x line-height, so two lines will display */
    overflow: hidden;  /* prevents extra lines from being visible */
}
.img_timeline{
    margin-bottom: 0px !important;
}
.img_timeline::before{
    background: none !important;
}
.img_timeline_item{
    margin-left: 0px !important;
}   

  .thumb .timeline-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    color: #495057;
    font-size: 16px;
    line-height: 1.1;
    margin: 0;
    padding: 10px;
  } 
   

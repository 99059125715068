.main_title{
    color:white !important;
    font-size: 1.5rem;
    font-family: 'Source Sans Pro', sans-serif !important;
}
.nav-sidebar .nav-item .nav-link .fa{
    margin-right: 1rem;
    
}
.nav-sidebar .nav-item  a{
    border-bottom: rgb(29 29 29 / 22%);
    border-bottom-style: groove;
}

@media only screen and (min-width: 700px) {
    .nav-menu-top-item{
        color:white !important;
    }
   .nav-item a{
        color:#FFFFFF !important;
    }
    .nav-item .mt-4{
        color:black;
    }
}
.nav-item a i{
    padding-right: 5px;
}
.nav-item a{
    top: 50%;
}
.nav-item .mt-4{
    color:black;
}
.newsettings{
/* padding:0px 0px !important; */
}
.logo_new{ 
    background-color:#da0325;
    font-size:20px;
    font-weight: bold;
    /* padding-left: 20px;
    padding-bottom: 5px; */
    padding:5px 10px;
}
.logo_new button{
    color:#fff;
}
.user_title{
    font-size:14px;
    font-weight: bold;
    /* padding-left: 20px;
    padding-bottom: 5px; */
    padding:5px 10px;
    color: black;
}
.title_name{
    padding-top: 10px;
}
#menu_opt li a{
    border-bottom: 1px solid #da0325;
}
#menu_opt12 {
    /*
    background-image: url(./assets/images/capture.PNG)!important;
    background-size: cover;
    background-repeat: no-repeat;
    */
    
      /* width: 240px;
      height: 240px; */
      /* background-color: #000; */
      background-image: radial-gradient(#de14141c 10%, transparent 11%),
        radial-gradient(#fff 10%, transparent 11%);
      background-size: 30px 30px;
      background-position: 0 0, 15px 15px;
      background-repeat: repeat;
    
  }
.App {
  text-align: center;
}

.chat {
    
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 10px;
  background:#da032599;
  position: fixed;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 99;
  display: inline-block;
  line-height: 50px;
  padding: 5px 1px;
}
.content-wrapper {
  /*
  background-image: url(./assets/images/capture.PNG)!important;
  background-size: cover;
  background-repeat: no-repeat;
  */
  
    /* width: 240px;
    height: 240px; */
    /* background-color: #000; */
    background-image: radial-gradient(#de14141c 10%, transparent 11%),
      radial-gradient(#fff 10%, transparent 11%);
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
    background-repeat: repeat;
  
}
 #tree{
  /* background-color: #270f17a6; */
} 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}
@import url(./assets/css/custom_css.css);

.mfp-container{
    z-index: 999999;
}
.mfp-wrap{
    z-index: 99999 !important;
}
.modal{
z-index: 1000 !important;
}
.modal-backdrop{
    z-index: 999 !important;
}
.imgLibThumb img{
    float: left;
    /* width:  100px;
    height: 100px; */
    object-fit: cover;
}
.imgLibThumb video{
    width:100%;
}
#details {
	resize: none;
	width: 100%;
	min-height: 10px;
	padding: 5px;
	/* overflow: hidden; */
	box-sizing: border-box;
}
.white_c a {
    color:#ffffff;
    opacity: 1 !important;
}
.modal-header .nav-item a{
    color:#000 !important;
}
.form-group {
margin-bottom: 0rem !important; 
}
.mod_new .ash-header{
    padding:0.5rem !important;
    border-bottom: none;
}
.ash-b-bottom{
    border-bottom:1px solid #da0325;
    margin-bottom: 5px;
}
.modal-footer{
    border-top: none !important;
}
.updImgThumb{
    display: none;
}
.lsthumb img{
    width: 100%;
    height: 50vh;
}
.custom-modal-header{
    padding:0.5rem !important;
    background-color:#da0325;
}
.white_c{
    color:#FFFFFF;
}
.main_dir button{
  white-space: nowrap;
   text-align: center;
}
.main_dir .show{
  z-index: 9999;
}
.main_dir {
  
    list-style-type: none;
    margin: 0;
    padding: 0;
     overflow-x: scroll; 
    /* overflow-y: auto; */
    white-space: nowrap;
    /* overflow-x: scroll; */
     display: flex; 
     flex-direction: row; 
     line-height: 20px;
     /* overflow-y:inherit; */
     text-overflow: ellipsis;
  }
 .main_dir select{
  border-radius: 10px;
  padding:5px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #FFFFFF;
}
.main_dir select option:hover{
  background-color: red;
} 
  
  .sub_dir {
    float: left;
    width: auto;
    /* display:inline; */
  }
  .no-border {
    border: 0 !important;
}
.less-padding{
  padding:0.1rem !important;
}
.btnLoader{
  margin-bottom: 5px;
}
.modal_new{
  overflow-y: hidden;
}
.mf-footer{
  padding:0px 0px !important;
}


.col-12 iframe{
    width: 100%;
    height: 100vh;
}
.vidNext{
    z-index: 9999 !important;position: fixed;display: none;margin-left: 300px;background-color: Transparent;
    background-repeat:no-repeat;
    top:80px;
    cursor:pointer;
    overflow: hidden;
    outline:none;border:solid 2px white;width: 200px;font-size: 25px;color: white;
}
.custome-lable-right::before{
    position: absolute;
    top: 0.25rem;
    left: 0rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: "";
 background-image: url("../assets/images/doc-green-sign.jpg");
}
.custome-lable-wrong::before{
    position: absolute;
    top: 0.25rem;
    left: 0rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: "";
 background-image: url("../assets/images/doc-red-sign.jpg");
}
.correct_ans_color{
    color:green;
}
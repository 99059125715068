.modal_new{
    width: 100%!important;
    height: 100%!important;
    max-width: 100%!important;
    max-height: 100%!important;
    margin:0px!important;
}
.mod_new{
    padding:0px!important;
    margin: 0px!important;
}

.modc{ height: 100%!important;}
.mf-footer{text-align: center; position:fixed; width: 98%; border-radius: 5px; background-color: #fff; margin-left: 1%; bottom: 0px;}
.modal-body {
    height: auto !important;
    /* max-height: calc(100vh - 50px) !important; */
    max-height: 70vh;
    overflow: auto;
}
.md_close{ float:right; margin-top:10px}
.modal-header{ display: block!important;}
.sbtn{ float: right; margin-right:20px; margin-top: 5px;}
#parent_id{ height: 35px;}

audio
{
-webkit-transition:all 0.5s linear;
-moz-transition:all 0.5s linear;
-o-transition:all 0.5s linear;
transition:all 0.5s linear;
-moz-box-shadow: 2px 2px 4px 0px #006773;
-webkit-box-shadow:  2px 2px 4px 0px #006773;
box-shadow: 2px 2px 4px 0px #006773;
-moz-border-radius:7px 7px 7px 7px ;
-webkit-border-radius:7px 7px 7px 7px ;
border-radius:7px 7px 7px 7px ;
background: #f1f3f4;
margin:10px;
}
.audio_thumb_parent,.image_thumb_parent{ position: relative;}
.audio_thumb_parent .fa-close{ position:absolute; color: #000; top: 15px; right:15px; z-index: 9; background: #fff;}
.image_thumb_parent .fa-close{position: absolute; color: #000; top: 0px; right:0px; z-index: 9; background: #fff; font-size: 16px;}

.image_thumb_parent img{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    height: auto;
}